<template>
  <el-popover
    popper-class="dapartment_tree_selector"
    placement="bottom-start"
    trigger="manual"
    :visible-arrow="false"
    :width="width"
    v-clickoutside="handleClose"
    v-model.trim="visible"
  >
    <el-input
      ref="input"
      :placeholder="placeholder"
      slot="reference"
      :value="departName"
      @focus="handleFocus"
      clearable
      @clear="handleClear"
    ></el-input>
    <div ref="categoryPopover">
      <el-input :placeholder="placeholder" :size="size" v-model.trim="keyword"></el-input>
      <el-tree
        :data="targetLists"
        node-key="id"
        :size="size"
        ref="categoryTree"
        show-checkbox
        :default-expanded-keys="defaultExpandedKey"
        :default-checked-keys="value.slice(0)"
        :filter-node-method="filterNode"
        :props="{
          label: data => data.name,
          children: 'children',
        }"
      ></el-tree>
      <el-button type="primary" class="selectBtn" @click="selectDeptHandle(targetLists)">确定选中</el-button>
    </div>
  </el-popover>
</template>
<script>
import Clickoutside from 'element-ui/src/utils/clickoutside';
import SystemSetting from '@/api/systemSetting';
export default {
  name: 'DepartMentSelector',
  directives: { Clickoutside },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    name: {
      type: String,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'small',
    },
    isAllowLeaf: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default () {
        return '请选择上级部门';
      },
    },
    defaultExpandedKey: {
      type: Array,
      default: () => [],
    },
  },
  data () {
    console.log(this.value, 1);
    return {
      visible: false,
      targetLists: [],
      categoryCacheMap: {},
      width: 100,
      departName: this.name,
      defaultCheckedKeys: this.value.slice(0),
      keyword: '',
      // currentNodeKey: '',
    };
  },
  watch: {
    keyword (val) {
      this.$refs.categoryTree.filter(val);
    },
    async value () {
      let departList = this.targetLists;
      if (!departList || !departList.length) {
        departList = await SystemSetting.getDepartmentLists({});
        this.cacheNode(departList.body);
      }
      this.getChianName(this.value);
    },
    name (val = '') {
      this.departName = val;
    },
  },
  methods: {
    filterNode (value, data) {
      if (!value) return true;
      let label = data.name;
      return label.indexOf(value) !== -1;
    },
    handleFocus () {
      if (this.readonly) return;
      this.visible = !this.visible;
      this.getTargetLists();
    },
    selectDeptHandle (data) {
      if (data.level === 3 && !this.isAllowLeaf) {
        this.$JZShowAlert('部门最多支持三级管理，请重新选择上级部门！', 'error');
        return;
      }
      const checkedNodes = this.$refs.categoryTree.getCheckedNodes();
      this.departName = checkedNodes.map(i => i.name).join('，');
      this.$emit('change', checkedNodes);
      this.visible = false;
    },
    getChianName (nodeIdList) {
      if (!nodeIdList || !nodeIdList.length) {
        this.departName = '';
        return;
      }
      let selectedDepartmentList = [];
      nodeIdList.forEach(nodeId => {
        if (this.categoryCacheMap[nodeId]) {
          selectedDepartmentList.push(this.categoryCacheMap[nodeId].name);
        }
      });

      this.departName = selectedDepartmentList.join('，');
      this.$emit('update:name', this.departName);
    },
    handleClose () {
      this.visible = false;
      this.keyword = '';
    },
    getTargetLists () {
      SystemSetting.getDepartmentLists({}).then(this.getTargetListsSuccessFun);
    },
    getTargetListsSuccessFun (jsonData) {
      let currentLists = [];
      if (jsonData.heads.code === 200) {
        currentLists = jsonData.body || [];
      } else {
        this.$JZShowAlert(jsonData.heads.message, 'error');
      }
      this.cacheNode(currentLists);
      this.targetLists = currentLists || [];
      if (this.value?.length) {
        this.getChianName(this.value);
      } else {
        this.handleClear();
      }
    },
    handleClear () {
      this.$emit('input', []);
      this.$emit('update:name', '');
      this.$emit('change', []);
      this.departName = '';
    },
    calcPopoverWidth () {
      let rect = this.$refs.input.$el.getBoundingClientRect();
      this.width = rect.width;
    },
    cacheNode (sources) {
      sources.forEach(c => {
        this.categoryCacheMap[c.id] = c;
        if (c.children) {
          this.cacheNode(c.children);
        }
      });
    },
  },
  mounted () {
    this.calcPopoverWidth();
    this.popperElm = this.$refs.categoryPopover;
    window.addEventListener('resize', this.calcPopoverWidth);
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.calcPopoverWidth);
  },
};
</script>
<style lang="scss">
.dapartment_tree_selector {
  max-height: 300px;
  overflow: auto;

  .el-tree {
    padding-top: 8px;
  }
}
</style>
